import {Component, Input, OnInit} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldConfig } from '../../models/fields.interface';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {
  @Input() group: FormGroup;
  @Input() field: FieldConfig;
  constructor() { }

  ngOnInit() {
  }

}
