// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    org_id: '1',
    server_url_1: 'http://192.168.0.77:8086',
    user_microservice_url: 'http://10.10.0.111:9002/UserManagement',
    register_customer_suffix_test: '/visionfund/ussd/customer/registration',
    register_customer_suffix: '/customers/create',
    authentication_suffix: '/users/authenticate',
    deauthentication_suffix: '/users/deauthenticate',
    activate_user: '/users/activate',
    deactivate_user: '/users/deactivate',
    reset_password_suffix: '/users/resetPassword',
    initiate_reset_password_suffix: '/users/initiateResetPassword',
    identify_suffix: '/users/identify',
    register_user_suffix: '/users/register',
    update_user_suffix: '/users/updateById',
    get_users_suffix: '/users/all',
    get_users_by_id_suffix: '/users/byId',
    add_role_suffix: '/roles/create',
    update_role_suffix: '/roles/update',
    get_roles_suffix: '/roles/read?start=1&end=100',
    get_roles_by_user_suffix: '/roles/readByUser',
    get_roles_by_id: '/roles/readById',
    add_permission_suffix: '/permission/create',
    get_permissions_suffix: '/permission/read?start=1&end=2',
    get_permissions_by_role_id_suffix: '/permission/readByRoleId',
    get_configurations_suffix: '/configurations/getAll',
    get_ussd_service_configurations_suffix: '/configurations/getUssdServiceConfigs',
    get_global_service_configurations_suffix: '/configurations/getUssdGlobalParams',
    toggle_ussd_service_configurations_suffix: '/configurations/toggleUssdServiceConfigs',
    toggle_global_service_configurations_suffix: '/configurations/toggleUssdGlobalParams',
    update_configurations_suffix: '/configurations/updateById',
    get_customers_suffix: '/customers/read',
    activate_customer: '/customers/activatecustomer',
    deactivate_customer: '/customers/deactivatecustomer',
    get_dashboard_stats_suffix: '/reports/dashboard?id=3',
    get_mpesa_transactions_suffix: '/reports/getMpesaTransactions',
    search_customers_suffix: '/customers/search',
    get_customer_loans_suffix: '/reports/loan-applications',
    get_ussd_logs: '/reports/ussd-logs',
    get_customer_transactions_suffix: '/reports/getCustomerTransactions',
    get_all_customer_transactions: '/reports/allCustomerTransactions',
    get_one_customer_transactions: '/reports/oneCustomerTransactions',
    get_audit_logs: '/reports/audit-logs',
    get_messages_suffix: '/reports/getMessages',
    search_messages_suffix: '/reports/searchMessages',
    get_b2c_configs: '/configurations/getMpesaB2cCharges',
    get_core_settings: '/configurations/getSettings',
    update_core_settings: '/configurations/updateSettings',
    get_loan_cycle_configs_suffix: '/configurations/getLoanCycles',
    get_pd_adjustments_configs_suffix: '/configurations/getPDs',
    update_pd_adjustment_configs_suffix: '/configurations/updatePDs',
    update_loan_cycle_configs_suffix: '/configurations/updateLoanCycles',
    get_message_templates_suffix: '/configurations/getMessageTemplates',
    update_message_templates_suffix: '/configurations/updateMessageTemplate',
    get_branches: '/configurations/getBranches',
    update_b2c_configs: '/configurations/updateMpesaB2cCharges',
    getB1Reports: '/reports/getBiReports',
    get_sms_balance: '/reports/smsBalance',
    authorization: 'eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiIyIiwiaWF0IjoxNTc1OTU5MTkwLCJ1c2VybmFtZSI6Im10c2FtbXk0NCIsImlkIjoyLCJlbWFpbCI6Im10c2FtbXk0MDFAZ21haWwuY29tIiwibXNpc2RuIjoiMjU0NzI0MDQ5OTU2IiwiaXBBZGRyZXNzIjoiMDowOjA6MDowOjA6MDoxIiwidHRsTWlsbGlzIjoxMjAwMDAwMDAsInN1YiI6Im10c2FtbXk0NCIsImlzcyI6IlVzZXJNYW5hZ2VtZW50IiwiZXhwIjoxNTc2MDc5MTkwfQ.SZN4uzLIfSFjiNSaF0uwOkIqYGfxKpaA4NcxH3a0ZI4',
    inactiveTimeoutDuration: '1800',
    inactiveTimeoutDurationName: 'INACTIVE_TIMEOUT_DURATION',
    transactionTypes: ['LOAN_DISBURSEMENT', 'SAVINGS_DEPOSIT', 'LOAN_REPAYMENT', 'ALL'],
    channels: ['APP', 'USSD'],
    regex__phone_number: '^[0-9]{8,9}$',
    regex__password: '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})'
};
