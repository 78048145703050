import {Component, OnInit, ViewChild} from '@angular/core';
import {AuthCredential} from '../../../models/sharedInterfaces';
import {AuthService} from '../../../services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationService} from '../../../services/notification.service';
import {HttpErrorResponse} from '@angular/common/http';
import {tokenize} from '@angular/compiler/src/ml_parser/lexer';
import {UmUser} from '../../../models/user';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
    @ViewChild('cpassword', {static: true}) cpassword: Element;
    loadingBarColor = 'primary';
    registrationToggle: Boolean = false;
    isLoading: Boolean = false;
    user: UmUser = new UmUser();
    resetPasswordData: { newPassword: string, token: string, oldPassword: string } = {newPassword: '', token: '', oldPassword: ''};
    returnUrl: string;
    pattern = new RegExp(environment.regex__password);
    password_confirmation = '';
    passwordsMatch = false;

    constructor(private authService: AuthService, private route: ActivatedRoute, private router: Router,
                private ns: NotificationService) {
        if (this.authService.currentUserValue) {
            // this.router.navigate(['/dashboard']);
            console.log('User token is available');
        }
        this.route.queryParams.subscribe(res => {
            console.log('Params, ', res);
            this.resetPasswordData.token = res.token ? res.token : null;
            if (this.resetPasswordData.token === null) {
                this.ns.add('User Token not valid!', 'danger');
                setTimeout(() => {
                    this.router.navigate(['/login']).catch((err) => {
                        console.error('Error, ', err);
                    });
                }, 4000)
            }
        })
    }

    ngOnInit() {
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard';
        if (this.resetPasswordData.token) {
            // get userData
            this.authService.identify(this.resetPasswordData.token).subscribe((res: any) => {
                this.user = res.entity['user'];
            }, error => {
                const errMsg = error.error && error.error.responseDescription ? error.error.responseDescription : 'E0001';
                this.ns.add('Error! Could not get your information. Kindly try again.' + errMsg, 'danger');
            })
        }
    }

    onSubmitResetPassword() {
        this.isLoading = true;
        this.authService.resetPassword(this.resetPasswordData).subscribe((res) => {
            this.isLoading = false;
            if (res && res.responseCode === '00') {
                this.ns.add('Reset Successful! Redirecting...', 'success');
                this.router.navigate(['/login']).then((data) => {
                    console.log('Successful Reset');
                }).catch(err => console.error('Error Resetting in: Redirect >> ', err));
            } else {
                const responseDescription = res ? res.responseDescription : null
                this.ns.add('Could not reset password: ' + responseDescription, 'danger');
            }
        }, (error: HttpErrorResponse) => {
            this.isLoading = false;
            if (error.status === 401) {
                this.ns.add('Incorrect credentials!', 'danger');
            } else {
                const errMsg = error.error && error.error.responseDescription;
                this.ns.add('Error! Could not reset password!' + errMsg, 'danger');
            }
        });
    }

    passwordsDoMatch(event, otherString: String) {
        if (event.target.value === otherString) {
            this.passwordsMatch = true;
        } else {
            this.passwordsMatch = false;
        }
    }
}
