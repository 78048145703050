import {Component, ViewChild, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FieldConfig} from '../../models/fields.interface';
import {TransactionsLogPayload} from '../../models/modelBank';
import * as moment from 'moment';

@Component({
    selector: 'app-filter-form',
    templateUrl: './filter-form.component.html',
    styleUrls: ['./filter-form.component.scss']
})
export class FilterFormComponent implements OnInit {
    @Input() formConfigs: FieldConfig[];
    @Output() formResult = new EventEmitter<any>();
    myFormGroup: FormGroup;
    formTemplate: FieldConfig[];

    constructor() {
    }

    ngOnInit() {
        this.formTemplate = this.formConfigs;
        const group = {}
        this.formConfigs.forEach(input_template => {
            if (input_template.type !== 'button') {
                group[input_template.name] = new FormControl('', this.bindValidations(input_template.validations || []))
            }
        });
        this.myFormGroup = new FormGroup(group);
    }

    onSubmit() {
        const formValue: Object = this.myFormGroup.value;
        console.log('Form Submitted:', formValue);
        if (typeof this.myFormGroup.value.enddate === 'string') {
            console.log('String date to transform >> ', this.myFormGroup.value.enddate);
            // moment(this.myFormGroup.value.enddate, 'YYYYMMDDHHmmss').format('YYYYMMDDHHmmss');
            // moment(this.myFormGroup.value.startdate, 'YYYYMMDDHHmmss').format('YYYYMMDDHHmmss');
        }
        Object.keys(formValue).forEach((k) => {
            if (formValue[k] instanceof moment) {
                console.log('Key ' + k + ' is instance of moment. Value >>', formValue[k]);
                formValue[k] = formValue[k].format('YYYYMMDDHHmmss');
                console.log('after transformation to string >>', formValue[k]);
            }
        });
        this.formResult.emit(formValue);
    }

    bindValidations(validations: any) {
        if (validations.length > 0) {
            const validList = [];
            validations.forEach(valid => {
                validList.push(valid.validator);
            });
            return Validators.compose(validList);
        }
        return null;
    }
}
