import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {FooterComponent} from './footer/footer.component';
import {NavbarComponent} from './navbar/navbar.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {FilterFormComponent} from './filter-form/filter-form.component';
import {InputComponent} from './input/input.component';
import {ButtonComponent} from './button/button.component';
import {SelectComponent} from './select/select.component';
import {DateComponent} from './date/date.component';
import {CheckBoxComponent} from './check-box/check-box.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MatInput, MatInputModule} from '@angular/material/input';
import {MatSelect, MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatNativeDateModule, MatOptionModule} from '@angular/material/core';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatListModule} from '@angular/material/list';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatButtonModule} from '@angular/material/button';
import {MatRadioModule} from '@angular/material/radio';
import {MatDatepickerModule} from '@angular/material/datepicker';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatIconModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatListModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatMomentDateModule,
        MatSelectModule,
        MatOptionModule,
        MatCheckboxModule,
        MatRadioModule
    ],
    declarations: [
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        FilterFormComponent,
        InputComponent,
        ButtonComponent,
        SelectComponent,
        DateComponent,
        CheckBoxComponent,
    ],
    entryComponents: [
        ButtonComponent,
        SelectComponent,
        DateComponent,
        CheckBoxComponent,
        InputComponent,
    ],
    exports: [
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        FilterFormComponent,
        InputComponent,
        ButtonComponent,
        SelectComponent,
        DateComponent,
        CheckBoxComponent,
    ]
})
export class ComponentsModule {
}
