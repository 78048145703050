import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthService} from '../services/auth.service';
import {environment} from '../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    token: any;
    // excluded Urls - do not need tokens
    requestMap = ({
        [environment.authentication_suffix] : true,
        [environment.reset_password_suffix] : true,
    });

    constructor(private authService: AuthService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        if (this.requestMap[request.url]) {
            return next.handle(request);
        }
        if (this.authService.currentUserValue) {
            const cloneRequest = request.clone({
                setHeaders: {Ulinzi: this.authService.currentUserValue.token}
            });
            return next.handle(cloneRequest);
        } else {
            return next.handle(request);
        }
    }
}
