import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {NotificationService} from './notification.service';
import {DataService} from './data.service';
import {UmUser} from '../models/user';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {AuthCredential} from '../models/sharedInterfaces';
import {catchError, map} from 'rxjs/operators';
import {UmRole} from '../models/role';
import {UmPermission} from '../models/permission';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Configurations} from '../models/configurations';
import {Utils} from '../helpers/utils';
import {FunctionResponse, PrintableRequest} from '../models/modelBank';
import {Constants} from '../helpers/constants';
import {Menus} from '../components/menus.interface';

export interface AuthDetails {
    user: UmUser;
    token: string;
}

export const ROUTES: Menus[] = [
    {path: '/dashboard', title: 'Dashboard', icon: 'dashboard', class: ''},
    {path: '/administration', title: 'Administration', icon: 'work', class: ''},
    {path: '/users', title: 'User Management', icon: 'lock', class: ''},
    {path: '/customers', title: 'Customers', icon: 'people', class: ''},
    {path: '/transactions', title: 'Transactions', icon: 'compare_arrows', class: ''},
    {path: '/loans', title: 'Loan Management', icon: 'attach_money', class: ''},
    {path: '/reports', title: 'Reports', icon: 'assessment', class: ''},
    {path: '/settings', title: 'Settings', icon: 'settings_application', class: ''},
    // { path: '/user-profile', title: 'User Profile',  icon:'person', class: '' },
    // { path: '/upgrade', title: 'Upgrade to PRO',  icon:'unarchive', class: 'active-pro' },
];

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    constants = new Constants();
    responseTypes = ({
        [this.constants.REPORT_TYPES.CSV]: 'text',
        [this.constants.REPORT_TYPES.EXCEL]: 'blob',
    });
    public currentUserSubject: BehaviorSubject<AuthDetails>;
    public permissions: UmPermission[] = [];
    public allowedMenus: string[] = [];
    users: UmUser[] = [];
    public permissionsBSubject: BehaviorSubject<UmPermission[]>;
    // TODO make auto-logout time dynamic
    private logoutTime = Number(environment.inactiveTimeoutDuration);
    timeoutHandler: any;
    private configurations: Configurations[];
    public configurationsBSubject: BehaviorSubject<Configurations[]>;
    private portalConfigurations: PortalSetting[] = [];
    public portalConfigurationsBSubject: BehaviorSubject<PortalSetting[]>;
    public usersBsubject: BehaviorSubject<UmUser[]> = new BehaviorSubject(this.users);
    public menuItems: Menus[] = [];
    public menuItemsBSubject: BehaviorSubject<Menus[]>;

    constructor(
        private dataService: DataService,
        private router: Router,
        private httpClient: HttpClient,
        private notificationService: NotificationService,
        private matSnackBar: MatSnackBar
    ) {
        this.currentUserSubject = new BehaviorSubject<AuthDetails>(null);
        this.permissionsBSubject = new BehaviorSubject<UmPermission[]>(this.permissions);
        this.configurationsBSubject = new BehaviorSubject<Configurations[]>(this.configurations);
        this.portalConfigurationsBSubject = new BehaviorSubject<PortalSetting[]>(this.portalConfigurations);
        this.menuItemsBSubject = new BehaviorSubject<Menus[]>(this.menuItems);
        this.getMenuItems();

    }

    public get currentUserValue(): any {
        return this.currentUserSubject.value;
    }

    public get myPermissions(): any {
        return this.permissionsBSubject.value;
    }

    computeOptions(pageData) {
        const options: { responseType: any } = {
            responseType: this.responseTypes[pageData.reportType] ? this.responseTypes[pageData.reportType] : null
        };
        return options;
    }

    login(authCredential: AuthCredential): Observable<any> {
        return this.httpClient.post<any>(environment.user_microservice_url + environment.authentication_suffix, authCredential).pipe(map(
            (res: any) => {
                if (res && res.responseCode === '00') {
                    this.setCurrentUserSession(res.entity);
                }
                return res;
            }
        ));
    }

    resetPassword(resetPasswordData: any): Observable<any> {
        const headers = new HttpHeaders().append('Ulinzi ', resetPasswordData.token);
        return this.httpClient
            .post<any>(environment.user_microservice_url + environment.reset_password_suffix, resetPasswordData, {headers: headers});
    }

    initiateResetPassword(email: String): Observable<any> {
        const queryString = new Utils().makeQueryString({email: email});
        return this.httpClient.get(environment.user_microservice_url + environment.initiate_reset_password_suffix + queryString);
    }

    identify(token: string) {
        const headers = new HttpHeaders().append('Ulinzi ', token);
        return this.httpClient.get(environment.user_microservice_url + environment.identify_suffix, {headers: headers});
    }

    deauthenticate(): Observable<any> {
        return this.httpClient.get(`${environment.user_microservice_url + environment.deauthentication_suffix}`);
    }

    async fetchPermissions(): Promise<FunctionResponse> {
        if (this.sessionIsPresent()) {
            const permissions = await this.getAllPermissions().toPromise();
            if (permissions.length > 0) {
                // proceed
                return {
                    status: this.constants.FUNCTION_RESPONSE.STATUS.OK,
                    data: permissions
                };
            } else {
                return {
                    status: this.constants.FUNCTION_RESPONSE.STATUS.ERROR,
                    error: 'Permissions not found'
                };
            }
        } else {
            return {
                status: this.constants.FUNCTION_RESPONSE.STATUS.ERROR,
                error: this.constants.FUNCTION_RESPONSE.ERRORS.AUTHENTICATION.NO_USER_DETECTED
            };
        }
    }

    // For configuration logic - Not used for listing
    fetchConfigurations(): Observable<any> {
        return this.httpClient
            .get(`${environment.user_microservice_url +
            environment.get_configurations_suffix}?organisationId=${environment.org_id}&category=PORTAL`)
            .pipe(map((res: any) => {
                if (res && res.responseCode === '00') {
                    this.configurations = res.recordCount > 0 ? res.entity : [];
                    this.configurationsBSubject.next(Object.assign({}, this.configurations));
                    const timeoutConfig = this.configurations.find((c) => c.name === environment.inactiveTimeoutDurationName);
                    if (timeoutConfig) {
                        this.logoutTime = Number(timeoutConfig.value);
                    }
                    this.autoLogoutTimer();
                } else if (res && res.responseCode !== '00') {
                    const errMsg = res.responseDescription ? res.responseDescription : '';
                    this.notificationService.add('Error: Could not fetch configuration data! ' + errMsg, 'danger');
                } else {
                    this.notificationService.add('Error: Could not fetch configuration data! ', 'danger');
                }
                return res;
            }), catchError((err) => {
                const errMsg = err.message ? err.message : err;
                this.notificationService.add('Error! Could not fetch configurations' + err, 'danger');
                return of(false);
            }));
    }

    // For listing only
    getConfigurations(category: String): Observable<any> {
        return this.httpClient
            .get(`${environment.user_microservice_url +
            environment.get_configurations_suffix}?organisationId=${environment.org_id}&category=${category}`)
            .pipe(map((response: any) => {
                if (response.responseCode === '00') {
                    this.configurations = response.recordCount > 0 ? response.entity : [];
                    this.configurationsBSubject.next(Object.assign({}, this.configurations));
                    return response.entity;
                } else {
                    return [];
                }
            }))
    }

    updateConfigurations(configuration: PortalSetting): Observable<any> {
        return this.httpClient
            .post(`${environment.user_microservice_url + environment.update_configurations_suffix}`, configuration);
    }

    register(user: UmUser): Observable<any> {
        return this.httpClient.post<any>(environment.user_microservice_url + environment.register_user_suffix, user);
    }

    addRole(role: UmRole): Observable<any> {
        return this.httpClient.post(environment.user_microservice_url + environment.add_role_suffix, role);
    }

    updateRole(role: UmRole): Observable<any> {
        return this.httpClient.post(environment.user_microservice_url + environment.update_role_suffix, role);
    }

    getAllRoles(): Observable<any> {
        return this.httpClient.get(environment.user_microservice_url + environment.get_roles_suffix)
            .pipe(map((res: any) => {
                if (res.responseCode === '00') {
                    return res.entity;
                } else {
                    return false;
                }
            }));
    }

    getRolesByUserId(id): Observable<any> {
        return this.httpClient.get(environment.user_microservice_url + environment.get_roles_by_user_suffix + '?id=' + id);
    }

    getUsers(pageData: PrintableRequest): Observable<any> {
        let queryString: string = new Utils().makeQueryString(pageData);
        queryString = queryString ? queryString : '';
        return this.httpClient.get(environment.user_microservice_url + environment.get_users_suffix + queryString,
            this.computeOptions(pageData))
            .pipe(map((res: any) => {
                if (res && res.responseCode === '00') {
                    this.users = res.entity.content;
                    this.usersBsubject.next(Object.assign([], this.users));
                }
                return res;
            }));
    }

    updateUser(user: UmUser): Observable<any> {
        return this.httpClient.post(environment.user_microservice_url + environment.update_user_suffix, user);
    }

    getUsersById(id): Observable<any> {
        return this.httpClient.get(environment.user_microservice_url + environment.get_users_by_id_suffix+ '?id=' + id);
    }

    getAllPermissions(): Observable<any> {
        return this.httpClient.get<any>(environment.user_microservice_url + environment.get_permissions_suffix).pipe(
          map((res) => {
            console.log('Permissions response:', res); // Log the response
            if (res) {
              this.permissions = res;
              this.permissionsBSubject.next(this.permissions);
              return res;
            } else {
              console.error('Empty response: Failed to fetch permissions');
              throw new Error('Failed to fetch permissions');
            }
          }),catchError((error) => {
            const err = error.error.responseDescription ? error.error.responseDescription : 'E0001';
            console.error('Error occurred while fetching permissions:', error);
            this.notificationService.add('Error! Failed to fetch permissions: ' + err, 'danger');
            return(error);
          })
        );
      }    

    getPermissionsByRoleId(roleId): Observable<any> {
        return this.httpClient.get(`${environment.user_microservice_url + environment.get_permissions_by_role_id_suffix + '?roleId=' + roleId}`);
         
    }

    getRolesById(id): Observable<any> {
        return this.httpClient.get(environment.user_microservice_url + environment.get_roles_by_id + '?id=' + id);
    }

    getMenuItems() {
        this.permissionsBSubject.asObservable().subscribe((res: UmPermission[]) => {
            res.forEach((permission) => {
                if (permission.isMenu) {
                    this.allowedMenus.push(permission.url);
                }
                this.menuItems = ROUTES.filter(menuItem => this.allowedMenus.includes(menuItem.path));
                this.menuItemsBSubject.next(Object.assign([], this.menuItems));
            });
            return this.menuItems;
        })
    }

    activateUser(data): Observable<any> {
        const queryString = new Utils().makeQueryString(data);
        return this.httpClient.get(environment.user_microservice_url + environment.activate_user + queryString);
    }

    deactivateUser(data) {
        const queryString = new Utils().makeQueryString(data);
        return this.httpClient.get(environment.user_microservice_url + environment.deactivate_user + queryString);
    }

    activateCustomer(id): Observable<any> {
        return this.httpClient.get(environment.user_microservice_url + environment.activate_customer + '?id=' + id);
    }

    deactivateCustomer(id) {
        return this.httpClient.get(environment.user_microservice_url + environment.deactivate_customer + '?id=' + id);
    }
    setCurrentUserSession(entity) {
        sessionStorage.setItem('_user', JSON.stringify(entity.user));
        sessionStorage.setItem('_token', entity.token);
        this.currentUserSubject.next({user: entity.user, token: entity.token});
    }

    destroyCurrentUSerSession() {
        sessionStorage.removeItem('_user');
        sessionStorage.removeItem('_token');
        this.currentUserSubject.next(null);
    }

    sessionIsPresent(): boolean {
        if (sessionStorage.getItem('_user') && sessionStorage.getItem('_token')) {
            if (!this.currentUserSubject.value) {
                this.currentUserSubject.next({user: JSON.parse(sessionStorage.getItem('_user')), token: sessionStorage.getItem('_token')});
            }
            return true;
        } else {
            console.error('Session not found');
            return false;
        }
    }

    /**
     *
     * checks is a permission linked to the url exists
     * @param url
     * @returns Boolean
     */
    hasPermissionFor(url: string): Boolean {
        //    cross reference the permissions with the ones fetched
        const urls: string[] = url.split('/');
        const parentPermission: UmPermission = this.permissions.find(p => p.url === '/' + urls[0]);
        if (parentPermission) {
            // Parent permission found -> does it contain a child with second part of the url?
            const chiildPermission = this.permissions.find(p => p.isMenu === 0 && p.url === '/' + urls[1])
            return !!chiildPermission;
        } else {
            // No parent permission found
            return false;
        }
    }

    async openSnackBar(message: string, action: string) {
        const loginPromptSnackbar = await this.matSnackBar.open(
            message,
            action, {
                duration: 7000,
                announcementMessage: 'announcement'
            });
        loginPromptSnackbar.onAction().subscribe(() => {
            loginPromptSnackbar.dismiss();
            this.logout();
        })
        loginPromptSnackbar.afterDismissed().subscribe((res) => {
            this.logout();
        })
    }


    extendLogout() {
        this.autoLogoutTimer();
    }

    autoLogoutTimer() {
        if (this.timeoutHandler) {
            clearTimeout(this.timeoutHandler);
        }
        this.timeoutHandler = setTimeout(async () => {
            await this.openSnackBar('You have been logged out due to inactivity', 'Ok');
        }, this.logoutTime);
    }

    logout() {
        this.deauthenticate()
          .toPromise()
          .then((res) => {
            return res;
          })
          .catch((err) => {
            console.error('Could not deauthenticate: ', err);
            this.notificationService.add('Token Invalidation failed!', 'danger');
          })
          .finally(() => {
            this.destroyCurrentUSerSession();
            this.router.navigate(['/login']);
            this.menuItems = [];
            this.menuItemsBSubject.next([...this.menuItems]);
            this.permissions = [];
            this.permissionsBSubject.next([...this.permissions]);
            this.dataService.cleanUp();
        })
    }
}
