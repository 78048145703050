import {Component, Input, OnInit} from '@angular/core';
import {FormGroup, Form} from '@angular/forms';
import { FieldConfig } from '../../models/fields.interface';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})

export class InputComponent implements OnInit {
  @Input() field: FieldConfig;
  @Input() group: FormGroup;
  constructor() { }

  ngOnInit() {
  }

}
