import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {LoadingService} from '../../../services/loading.service';
import * as moment from 'moment';
import {CSV} from '../../../models/modelBank';

@Component({
    selector: 'app-export-csv-dialog',
    templateUrl: './export-csv-dialog.component.html',
    styleUrls: ['./export-csv-dialog.component.scss']
})
export class ExportCsvDialogComponent implements OnInit {
    isProcessing;
    @ViewChild('downloadLink', {static: true}) downloadLinkRef: ElementRef;

    constructor(public dialogRef: MatDialogRef<ExportCsvDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any, private loadingService: LoadingService) {
    }

    ngOnInit() {
        this.loadingService.csvDataIsLoading.subscribe(res => {
            this.isProcessing = res;
        });
        this.generateDownloadFile(this.data.filename, this.data.content);
    }

    generateDownloadFile(fileName, urlData) {
        // const aLink: HTMLAnchorElement = document.getElementById('download-link');
        // console.log('download-link >> ', aLink.getAttribute('id'));
        this.downloadLinkRef.nativeElement.download = fileName;
        this.downloadLinkRef.nativeElement.href = urlData;
        // aLink.download = fileName;
        // aLink.href = urlData;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

}
