import { Injectable } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})

export class RouteWatcherService {

    constructor(router: Router,
        private authService: AuthService) {
        router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                const navEndEvent: NavigationEnd = event;
            }
        });
    }

    handleNavigationEnd(event: NavigationEnd) {
        switch (event.url) {
            case '/settings':''
                this.authService.getConfigurations('PORTAL');
        }
    }
}