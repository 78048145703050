import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../environments/environment';
import {EnvironmentConfiguration} from './models/environmentConfiguration';
import {LoadingService} from './services/loading.service';
import {Constants} from './helpers/constants';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    configLoaded: Boolean = false;
    envConfig: EnvironmentConfiguration;
    topBarState = new Constants().TOP_BAR;

    constructor(private http: HttpClient,
                public loadingService: LoadingService) {
    }

    ngOnInit() {
        this.http.get<any>('assets/config.json').subscribe(res => {
            this.envConfig = res;
            this.configLoaded = true;
            Object.keys(this.envConfig).forEach(k => {
                environment[k] = this.envConfig[k];
            });
        }, err => {
            this.configLoaded = true
        });
    }
}
