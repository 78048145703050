import {Component, OnInit, Input} from '@angular/core';
import {Menus} from '../menus.interface'
import {FormBuilder, FormGroup} from '@angular/forms';
import {UmPermission} from '../../models/permission';
import {log} from 'util';
import {AuthService} from '../../services/auth.service';

declare const $: any;
// declare interface RouteInfo {
//     path: string;
//     title: string;
//     icon: string;
//     class: string;
// }

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
    permissions: UmPermission[] = [];
    menuItems: Menus[];
    searchForm: FormGroup;

    constructor(private fb: FormBuilder,
                private authService: AuthService) {
    }

    ngOnInit() {
        this.searchForm = this.fb.group({
            searchInput: ['']
        });
        // this.menuItems = ROUTES.filter(menuItem => menuItem);
        this.authService.menuItemsBSubject.asObservable().subscribe(res => this.menuItems = res);
    }

    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };

}
