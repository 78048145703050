import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog'
import {AppRoutingModule} from './app.routing';
import {ComponentsModule} from './components/components.module';
import {AppComponent} from './app.component';
import {RouteWatcherService} from './services/routeWatcher.service';
import {
    AgmCoreModule
} from '@agm/core';
import {AdminLayoutComponent} from './layouts/admin-layout/admin-layout.component';
import {LoginComponent} from './pages/login/login.component';
import {
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatDialogModule,
    MatSortModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
} from '@angular/material';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AuthInterceptor} from './interceptors/auth.interceptor';
import {NotificationService} from './services/notification.service';
import {AuthService} from './services/auth.service';
import {AuthErrorInterceptor} from './interceptors/auth-error.interceptor';
import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';
import {LoadingBarModule} from '@ngx-loading-bar/core';
import {BrowserModule} from '@angular/platform-browser';
import {LayoutModule} from '@angular/cdk/layout';
import {CdkTableModule} from '@angular/cdk/table';
import {DataService} from './services/data.service';
import {ChartsModule} from 'ng2-charts';
import {MatCardModule} from '@angular/material/card';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {NetworkStatusInterceptor} from './interceptors/network-status.interceptor';
import {LoadingService} from './services/loading.service';
import {ResetPasswordComponent} from './pages/users/reset-password/reset-password.component';
import {ForgotPasswordComponent} from './pages/modals/forgot-password/forgot-password.component';
import {ModalService} from './services/modal.service';

@NgModule({
    imports: [
        CdkTableModule,
        BrowserAnimationsModule,
        BrowserModule,
        FormsModule,
        HttpClientModule,
        ComponentsModule,
        RouterModule,
        AppRoutingModule,
        MatFormFieldModule,
        MatButtonModule,
        MatInputModule,
        LoadingBarHttpClientModule,
        LoadingBarRouterModule,
        LoadingBarModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        LayoutModule,
        MatDialogModule,
        MatSortModule,
        ChartsModule,
        MatCardModule,
        MatSnackBarModule
    ],
    declarations: [
        AppComponent,
        AdminLayoutComponent,
        LoginComponent,
        ResetPasswordComponent,
        ForgotPasswordComponent,
    ],
    entryComponents: [
        ForgotPasswordComponent
    ],
    providers: [
        AuthService,
        NotificationService,
        DataService,
        RouteWatcherService,
        LoadingService,
        ModalService,
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: AuthErrorInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: NetworkStatusInterceptor, multi: true},
        {provide: MatDialogRef, useValue: {}},
        {provide: MAT_DIALOG_DATA, useValue: []},
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
