import {Injectable} from '@angular/core';
import {ExportCsvDialogComponent} from '../pages/modals/export-csv-dialog/export-csv-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {Observable, of} from 'rxjs';
import {tryCatch} from 'rxjs/internal-compatibility';

@Injectable()
export class ModalService {
    constructor(
        public dialog: MatDialog
    ) {
    }

    async openDialog(csvData): Promise<any> {
        let data: any;
        const dialogRef = await this.dialog.open(ExportCsvDialogComponent, {
            width: '500px',
            panelClass: 'export-csv-modal',
            data: csvData
        });

        await dialogRef.afterClosed().toPromise().then(result => {
            console.log('The dialog was closed', result);
            if (result) {
                return data = result;
            }
            data = {
                empty: true
            };
            return data
        });
    }
}
