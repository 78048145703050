import {Injectable} from '@angular/core';
import {User} from '../models/user';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {Customer} from '../models/customer';
import * as uuid from 'uuid';
import {Role} from '../models/role';
import {Permission, UmPermission} from '../models/permission';
import {SystemConfig} from '../models/system-config';
import {Transaction} from '../models/transaction';
import {AccountingEntry} from '../models/accounting-entry';
import {LoanApplication} from '../models/loan-application';
import {Branch} from '../models/branch';
import {Service} from '../models/service';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {first, map, catchError} from 'rxjs/operators';
import {NotificationService} from './notification.service';
import {GlobalSetting} from '../models/global-setting';
import {ResponseCode} from '../models/response-code';
import {Message} from '../models/message';
import {ChannelLog} from '../models/channel-log';
import {UssdLog} from '../models/ussd-log';
import {B2cConfig} from '../pages/settings/b2c-configs/b2c-configs.component';
import {
    AccountingEntriesSearchObject, BiReport,
    CoreSetting, CustomerSearchObject,
    GlobalService,
    LoanCycle,
    MessageTemplate,
    PaginationObject,
    PdAdjustment,
    PrintableRequest,
    SearchObject,
    TransactionsLogPayload,
    CustomerTransactionSO
} from '../models/modelBank';
import {Utils} from '../helpers/utils';
import {Constants} from '../helpers/constants';
import {ResponseContentType} from '@angular/http';

@Injectable({
    providedIn: 'root'
})
export class DataService {
    constants = new Constants();
    today: Date = new Date();
    responseTypes = ({
        [this.constants.REPORT_TYPES.CSV]: 'text',
        [this.constants.REPORT_TYPES.EXCEL]: 'blob',
    });
    users: User[] = [];
    customers: Customer[] = [];
    roles: Role[] = [];
    permissions: Permission[] = [];
    configurations: SystemConfig[] = [];
    transactions: Transaction[] = [];
    accountingEntries: AccountingEntry[] = [];
    loanApplications: LoanApplication[] = [];
    branches: Branch[] = [];
    services: Service[] = [];
    globalSettings: GlobalSetting[] = [{
        parameter: 'SHORT_CODE',
        value: '*645*647#',
        description: 'Short code',
        date_created: this.today,
        active: true
    }];
    responseCodes: ResponseCode[] = [];
    biReports: BiReport[] = [];
    messages: Message[] = [];
    channelLogs: ChannelLog[] = [];
    b2cConfigs: B2cConfig[] = [];
    coreSettings: CoreSetting[] = [];
    ussdLogs: UssdLog[] = [];
    loanCycles: LoanCycle[] = [];
    pdAdjustments: PdAdjustment[] = [];
    messageTemplates: MessageTemplate[];
    globalServices: GlobalService[] = [];
    globalServicesBSubject: BehaviorSubject<GlobalService[]>;
    coreSettingsBSubject: BehaviorSubject<CoreSetting[]>;
    messageTemplatesBSubject: BehaviorSubject<MessageTemplate[]>;
    pdAdjustmentsBSubject: BehaviorSubject<PdAdjustment[]>;
    loanCyclesBSubject: BehaviorSubject<LoanCycle[]>;
    b2cConfigsBehaviorSubject: BehaviorSubject<B2cConfig[]>;
    ussdLogsBSubject: BehaviorSubject<UssdLog[]>;
    channelLogsBSubject: BehaviorSubject<ChannelLog[]>;
    messagesBSubject: BehaviorSubject<Message[]>;
    biReportsBSubject: BehaviorSubject<BiReport[]>;
    globalSettingsBSubject: BehaviorSubject<GlobalSetting[]>;
    responseCodesBSubject: BehaviorSubject<ResponseCode[]>;
    servicesBSubject: BehaviorSubject<Service[]>;
    branchesBSubject: BehaviorSubject<Branch[]>;
    loanApplicationsBSubject: BehaviorSubject<LoanApplication[]>;
    accountingEntriesBSubject: BehaviorSubject<AccountingEntry[]>;
    transactionsBSubject: BehaviorSubject<Transaction[]>;
    configurationsBSubject: BehaviorSubject<SystemConfig[]>;
    permissionsBSubject: BehaviorSubject<Permission[]>;
    rolesBSubject: BehaviorSubject<Role[]>;
    customersBSubject: BehaviorSubject<Customer[]>;
    userBSubject: BehaviorSubject<User[]>;

    constructor(private http: HttpClient, private notificationService: NotificationService) {
        this.customersBSubject = new BehaviorSubject<Customer[]>(this.customers);
        this.userBSubject = new BehaviorSubject<User[]>(this.users);
        this.rolesBSubject = new BehaviorSubject<Role[]>(this.roles);
        this.permissionsBSubject = new BehaviorSubject<Permission[]>(this.permissions);
        this.configurationsBSubject = new BehaviorSubject<SystemConfig[]>(this.configurations);
        this.transactionsBSubject = new BehaviorSubject<Transaction[]>(this.transactions);
        this.accountingEntriesBSubject = new BehaviorSubject<AccountingEntry[]>(this.accountingEntries);
        this.loanApplicationsBSubject = new BehaviorSubject<LoanApplication[]>(this.loanApplications);
        this.branchesBSubject = new BehaviorSubject<Branch[]>(this.branches);
        this.servicesBSubject = new BehaviorSubject<Service[]>(this.services);
        this.globalServicesBSubject = new BehaviorSubject<GlobalService[]>(this.globalServices);
        this.globalSettingsBSubject = new BehaviorSubject<GlobalSetting[]>(this.globalSettings);
        this.responseCodesBSubject = new BehaviorSubject<ResponseCode[]>(this.responseCodes);
        this.biReportsBSubject = new BehaviorSubject<BiReport[]>(this.biReports);
        this.messagesBSubject = new BehaviorSubject<Message[]>(this.messages);
        this.channelLogsBSubject = new BehaviorSubject<ChannelLog[]>(this.channelLogs);
        this.ussdLogsBSubject = new BehaviorSubject<UssdLog[]>(this.ussdLogs);
        this.b2cConfigsBehaviorSubject = new BehaviorSubject<B2cConfig[]>(this.b2cConfigs);
        this.coreSettingsBSubject = new BehaviorSubject<CoreSetting[]>(this.coreSettings);
        this.loanCyclesBSubject = new BehaviorSubject<LoanCycle[]>(this.loanCycles);
        this.pdAdjustmentsBSubject = new BehaviorSubject<PdAdjustment[]>(this.pdAdjustments);
        this.messageTemplatesBSubject = new BehaviorSubject<MessageTemplate[]>(this.messageTemplates);
    }


    deleteUser(id) {
        this.userBSubject.next(this.users.filter(x => x.id !== id))
    }

    computeOptions(pageData) {
        const options: { responseType: any } = {
            responseType: this.responseTypes[pageData.reportType] ? this.responseTypes[pageData.reportType] : null
        }
        return options;
    }

    getCustomers(pageData?: PrintableRequest): Observable<any> {
        let queryString: string = new Utils().makeQueryString(pageData);
        queryString = queryString ? queryString : '';
        return this.http.get(environment.user_microservice_url + environment.get_customers_suffix + queryString,
            this.computeOptions(pageData))
            .pipe(map((res: any) => {
                if (pageData.getPrintable) {
                    return res;
                }
                if (res.responseCode === '00') {
                    this.customersBSubject.next(Object.assign([], res.entity.customers.content));
                    return res.entity['customers'];
                } else if (res.responseCode === '02') {
                    return [];
                } else {
                    return false;
                }
            }));
    }

    searchCustomers(searchObject?: CustomerSearchObject): Observable<any> {
        let queryString: string = new Utils().makeQueryString(searchObject);
        queryString = queryString ? queryString : '';
        return this.http.get(environment.user_microservice_url + environment.search_customers_suffix + queryString,
            this.computeOptions(searchObject))
            .pipe(map((res: any) => {
                if (searchObject.getPrintable) {
                    return res;
                }
                if (res.responseCode === '00') {
                    this.customersBSubject.next(Object.assign([], res.entity.customers.content));
                    return res.entity['customers'];
                } else if (res.responseCode === '02') {
                    return [];
                } else {
                    return false;
                }
            }));
    }

    registerCustomer(customer: Customer): Observable<any> {
        return this.http.post<any>(`${environment.user_microservice_url + environment.register_customer_suffix}`, customer)
            .pipe(map((response, err) => {
                return response;
            }));
    }

    deleteCustomer(id): void {
        console.log('deleting customer id:', id);
        this.customersBSubject.next(this.customers.filter((x) => {
            return x.id !== id;
        }));
    }

    getCustomerLoans(pageData: PrintableRequest): Observable<any> {
        let queryString: string = new Utils().makeQueryString(pageData);
        queryString = queryString ? queryString : '';
        return this.http.get(environment.user_microservice_url +
            environment.get_customer_loans_suffix + queryString,
            this.computeOptions(pageData))
            .pipe(map((res: any) => {
                    if (pageData.getPrintable) {
                        return res;
                    }
                    if (res.responseCode === '00') {
                        const customerLoans: LoanApplication[] = res.entity['customer-loans']['content'];
                        this.loanApplicationsBSubject.next(Object.assign([], customerLoans));
                        console.log('Customer-loans >> ', customerLoans);
                        return res.entity;
                    } else if (res.responseCode === '02') {
                        return [];
                    } else {
                        return false;
                    }
                }
            ));
    }

    getUssdLogs(pageData: PrintableRequest): Observable<any> {
        let queryString: string = new Utils().makeQueryString(pageData);
        queryString = queryString ? queryString : '';
        return this.http.get(environment.user_microservice_url + environment.get_ussd_logs + queryString,
            this.computeOptions(pageData))
            .pipe(map((res: any) => {
                if (pageData.getPrintable) {
                    return res;
                }
                if (res.responseCode === '00') {
                    const ussdLogs: UssdLog[] = res.entity.ussd_logs.content;
                    this.ussdLogsBSubject.next(Object.assign([], ussdLogs));
                    return res.entity.ussd_logs;
                } else if (res.responseCode === '02') {
                    return [];
                } else {
                    return false;
                }
            }));
    }

    getAllTransactions(transactionQuery: TransactionsLogPayload): Observable<any> {
        return this.http.post<any>(`${environment.user_microservice_url + environment.get_all_customer_transactions}`, transactionQuery)
            .pipe(map((res: any) => {
                if (res.responseCode === '00') {
                    const transactions_ = res.entity.transactions;
                    this.transactionsBSubject.next(Object.assign([], transactions_));
                    console.log('Transactions', this.transactionsBSubject.value);
                } else if (res.responseCode === '02') {
                    this.notificationService.add('Did not find any transactions', 'danger');
                } else {
                    this.notificationService.add('Error! Could not fetch transactions.', 'danger');
                }
                return res;
            }, catchError((err, caught) => {
                console.error('Error! >>', err);
                console.log('caught! >>', caught);
                const errMsg = err.error && err.error.responseDescription ? err.error.responseDescription : 'E0001';
                this.notificationService.add('Error! Could not fetch transactions. ' + errMsg, 'danger');
                return caught;
            })))
    }

    getOneCustomerTransactions(transactionQuery: TransactionsLogPayload): Observable<any> {
        return this.http.post<any>(`${environment.user_microservice_url + environment.get_one_customer_transactions}`, transactionQuery)
            .pipe(map((res: any) => {
                if (res.responseCode === '00') {
                    const transactions_ = res.entity.transactions;
                    this.transactionsBSubject.next(Object.assign([], transactions_));
                    console.log('Transactions', this.transactionsBSubject.value);
                } else if (res.responseCode === '02') {
                    console.error('Error! Transactions not found');
                }
                return res;
            }, catchError(err => {
                console.error('Error! >>', err);
                const errMsg = err.error && err.error.responseDescription ? err.error.responseDescription : 'E0001';
                this.notificationService.add('Error! Could not fetch transactions. ' + errMsg, 'danger');
                return err;
            })));
    }

    getBranches(): Observable<Branch[]> {
        return this.http.get(`${environment.user_microservice_url + environment.get_branches}`).pipe(map((res: any) => {
            if (res && res.responseCode === '00') {
                this.branches = res.entity.branches;
                this.branchesBSubject.next(Object.assign([], this.branches));
                return this.branches;
            } else {
                return null;
            }
        }, catchError(err => {
            console.error('Error! >>', err);
            const errMsg = err.error && err.error.responseDescription ? err.error.responseDescription : 'E0001';
            this.notificationService.add('Error! Could not fetch branches. ' + errMsg, 'danger');
            return err;
        })));
    }

    deletePermission(id) {
        this.permissionsBSubject.next(this.permissions.filter(x => x.id !== id))
    }

    addConfiguration(configuration: SystemConfig) {
        configuration.id = uuid.v4();
        this.configurations.push(Object.assign({}, configuration));
        this.configurationsBSubject.next(this.configurations);
        return configuration;
    }

    deleteConfiguration(id) {
        this.configurationsBSubject.next(this.configurations.filter(res => res.id !== id))
    }

    getAuditLogs(paginationObject: PrintableRequest): Observable<any> {
        let queryString: string = new Utils().makeQueryString(paginationObject);
        queryString = queryString ? queryString : '';
        return this.http.get<any>(environment.user_microservice_url + environment.get_audit_logs + queryString,
            this.computeOptions(paginationObject))
            .pipe(map((res: any) => {
                    if (res.responseCode === '00') {
                        return res.entity['audit-logs'];
                    }
                    return res;
                },
                catchError(err => {
                        console.error('Error! >>', err);
                        const errMsg = err.error && err.error.responseDescription ? err.error.responseDescription : 'E0001';
                        this.notificationService.add('Error! Could not fetch audit logs. ' + errMsg, 'danger');
                        return err;
                    }
                )
                )
            );
    }

    getAccountingEntries(searchObject: AccountingEntriesSearchObject): Observable<any> {
        let queryString: string = new Utils().makeQueryString(searchObject);
        queryString = queryString ? queryString : '';
        return this.http.get<any>(environment.user_microservice_url + environment.get_mpesa_transactions_suffix + queryString,
            this.computeOptions(searchObject))
            .pipe(map((res: any) => {
                    if (res.responseCode === '00') {
                        // TODO change this to accounting entries
                        const accoountingEntries: AccountingEntry[] = res.entity['messages'].content;
                        this.accountingEntriesBSubject.next(Object.assign([], accoountingEntries));
                    }
                    return res;
                },
                catchError(err => {
                        console.error('Error! >>', err);
                        const errMsg = err.error && err.error.responseDescription ? err.error.responseDescription : 'E0001';
                        this.notificationService.add('Error! Could not fetch audit logs. ' + errMsg, 'danger');
                        return err;
                    }
                )
                )
            );
    }


    getB2cConfigs(): Observable<B2cConfig[]> {
        return this.http.get(`${environment.user_microservice_url + environment.get_b2c_configs}`)
            .pipe(map((res: any) => {
                if (res.responseCode === '00') {
                    const b2cConfigs: B2cConfig = res.entity;
                    return b2cConfigs;
                } else {
                    return res;
                }
            }));
        ;
    }

    updateB2cConfigs(b2cConfigs: B2cConfig): Observable<any> {
        return this.http.post(`${environment.user_microservice_url + environment.update_b2c_configs}`, b2cConfigs)
            .pipe(map((res: any) => {
                return res;
            }));
    }


    getCoreSettings(): Observable<any> {
        return this.http.get(`${environment.user_microservice_url + environment.get_core_settings}`)
            .pipe(map((res: any) => {
                if (res.responseCode === '00') {
                    this.coreSettings = res.entity['settings']['content'];
                    this.coreSettingsBSubject.next(Object.assign([], this.coreSettings));
                }
                return res;
            }));
    }

    updateCoreSettings(b2cConfigs: CoreSetting): Observable<any> {
        return this.http.post(`${environment.user_microservice_url + environment.update_core_settings}`, b2cConfigs)
            .pipe(map((res: any) => {
                return res;
            }));
    }

    addBranch(branch: Branch): Observable<any> {
        this.branches.push(Object.assign({}, branch));
        this.branchesBSubject.next(this.branches);
        return of(branch);
    }

    getLOanCycleConfigs(paginationObject): Observable<any> {
        let queryString: string = new Utils().makeQueryString(paginationObject);
        queryString = queryString ? queryString : '';
        return this.http.get(environment.user_microservice_url +
            environment.get_loan_cycle_configs_suffix + queryString)
            .pipe(map((res: any) => {
                if (res.responseCode === '00') {
                    this.loanCycles = res.entity['loan-cycles'].content;
                    this.loanCyclesBSubject.next(Object.assign([], this.loanCycles));
                    return res;
                } else {
                    return []
                }
                return null;
            }));
    }

    updateLoanCycleConfigs(loanCycle: any): Observable<any> {
        return this.http.post(environment.user_microservice_url + environment.update_loan_cycle_configs_suffix, loanCycle);
    }

    getPdConfigs(paginationObject): Observable<any> {
        let queryString: string = new Utils().makeQueryString(paginationObject);
        queryString = queryString ? queryString : '';
        return this.http.get(environment.user_microservice_url + environment.get_pd_adjustments_configs_suffix + queryString)
            .pipe(map(((res: any) => {
                if (res.responseCode && res.responseCode === '00') {
                    this.pdAdjustments = res.entity['pd-adjustments'].content;
                    this.pdAdjustmentsBSubject.next(Object.assign([], this.pdAdjustments));
                    return res;
                } else {
                    return []
                }
            })))
    }

    updatePdConfig(pdAdjustment: PdAdjustment): Observable<any> {
        return this.http.post(environment.user_microservice_url + environment.update_pd_adjustment_configs_suffix, pdAdjustment);
    }

    getMessageTemplates(paginationObject): Observable<any> {
        let queryString: string = new Utils().makeQueryString(paginationObject);
        queryString = queryString ? queryString : '';
        return this.http.get(environment.user_microservice_url + environment.get_message_templates_suffix + queryString)
            .pipe(map((res: any) => {
                if (res.responseCode === '00') {
                    this.messageTemplates = res.entity['message-templates'].content;
                    this.messageTemplatesBSubject.next(Object.assign([], this.messageTemplates));
                }
                return res;
            }))
    }

    updateMessageTemplates(messageTemplate: MessageTemplate): Observable<any> {
        return this.http.post(environment.user_microservice_url + environment.update_message_templates_suffix, messageTemplate);
    }

    getMessages(paginationObject: PrintableRequest): Observable<any> {
        let queryString: string = new Utils().makeQueryString(paginationObject);
        queryString = queryString ? queryString : '';
        return this.http.get(environment.user_microservice_url + environment.get_messages_suffix + queryString,
            this.computeOptions(paginationObject))
            .pipe(map((res: any) => {
                if (paginationObject.getPrintable) {
                    return res;
                }
                if (res.responseCode === '00') {
                    this.messages = res.entity['messages'].content;
                    this.messagesBSubject.next(this.messages);
                }
                return res;
            }))
    }

    searchMessages(searchObject: SearchObject): Observable<any> {
        let queryString: string = new Utils().makeQueryString(searchObject);
        queryString = queryString ? queryString : '';
        return this.http.get(environment.user_microservice_url + environment.search_messages_suffix + queryString)
            .pipe(map((res: any) => {
                if (res.responseCode === '00') {
                    this.messages = res.entity['messages'].content;
                    this.messagesBSubject.next(this.messages);
                }
                return res;
            }))
    }

    getService(): Observable<Service[]> {
        return this.http.get(`${environment.user_microservice_url
        + environment.get_ussd_service_configurations_suffix}`).pipe(map((res: any) => {
            console.log('code... ', res.responseCode);
            if (res && res.responseCode === '00') {
                const ussdServiceGroups: Service[] = res.entity.ussdServiceGroups.content;
                console.log('ussdServiceGroups', ussdServiceGroups);
                this.servicesBSubject.next(Object.assign([], ussdServiceGroups));
                return ussdServiceGroups;
            } else {
                return null;
            }
        }));
    }

    getGlobalService(): Observable<Service[]> {
        return this.http.get(`${environment.user_microservice_url
        + environment.get_global_service_configurations_suffix}`).pipe(map((res: any) => {
            console.log('code... ', res.responseCode);
            if (res && res.responseCode === '00') {
                const ussdServiceGroups: Service[] = res.entity['ussd-global-parameters'].content;
                console.log('ussdServiceGroups', ussdServiceGroups);
                this.globalServicesBSubject.next(Object.assign([], ussdServiceGroups));
                return ussdServiceGroups;
            } else {
                return null;
            }
        }));
    }

    updateService(updateData: { id: number; status: number }): Observable<any> {
        return this.http.post(`${environment.user_microservice_url +
        environment.toggle_ussd_service_configurations_suffix}`, updateData).pipe(map((res: any) => {
            if (res && res.responseCode === '00') {
                return res;
            } else {
                return null;
            }
        }))
    }

    updateGlobalService(updateData: { id: number; status: number }): Observable<any> {
        return this.http.post(`${environment.user_microservice_url +
        environment.toggle_global_service_configurations_suffix}`, updateData).pipe(map((res: any) => {
            if (res && res.responseCode === '00') {
                return res;
            } else {
                return null;
            }
        }))
    }

    getMpesaTransactions(paginationObject: PrintableRequest) {
        let queryString: string = new Utils().makeQueryString(paginationObject);
        queryString = queryString ? queryString : '';
        return this.http.get(environment.user_microservice_url + environment.get_mpesa_transactions_suffix + queryString,
            this.computeOptions(paginationObject))
            .pipe(map((res: any) => {
                if (paginationObject.getPrintable) {
                    return res;
                }
                if (res.responseCode === '00') {
                    this.messages = res.entity['mpesa-transactions'].content;
                    this.messagesBSubject.next(this.messages);
                }
                return res;
            }));
    }
    getBiReport(paginationObject: PrintableRequest ) {
        let queryString: string = new Utils().makeQueryString(paginationObject);
        queryString = queryString ? queryString : '';
        return this.http.get(environment.user_microservice_url + environment.getB1Reports + queryString,
            this.computeOptions(paginationObject))
            .pipe(map((res: any) => {
                if (paginationObject.getPrintable) {
                    return res;
                }
                if (res.responseCode === '00') {
                    this.biReports = res.entity['transactionSummary'].summaryList;
                    this.biReportsBSubject.next(this.biReports);
                }
                return res;
            }));
    }

    getCustomerTransactions(searchObject: CustomerTransactionSO): Observable<any> {
        let queryString: string = new Utils().makeQueryString(searchObject);
        queryString = queryString ? queryString : '';
        return this.http.get(environment.user_microservice_url + environment.get_customer_transactions_suffix + queryString,
            this.computeOptions(searchObject))
            .pipe(map((res: any) => {
                if (searchObject.getPrintable) {
                    return res;
                }
                if (res.responseCode === '00') {
                    this.transactions = res.entity['customer-transactions'].content;
                    this.transactionsBSubject.next(this.transactions);
                }
                return res;
            }));   
    }

    getSmsBalance(): Observable<any> {
        return this.http.get<any>(environment.user_microservice_url + environment.get_sms_balance);
    }

    getDashboardStats(): Observable<any> {
        return this.http.get(environment.user_microservice_url + environment.get_dashboard_stats_suffix);
    }

    addService(serviceId: Service) {
        serviceId.id = uuid.v4();
        this.services.push(Object.assign({}, serviceId));
        this.servicesBSubject.next(this.services);
        return serviceId;
    }
    cleanUp() {
        this.customersBSubject = new BehaviorSubject<Customer[]>([]);
        this.userBSubject = new BehaviorSubject<User[]>([]);
        this.rolesBSubject = new BehaviorSubject<Role[]>([]);
        this.permissionsBSubject = new BehaviorSubject<Permission[]>([]);
        this.configurationsBSubject = new BehaviorSubject<SystemConfig[]>([]);
        this.transactionsBSubject = new BehaviorSubject<Transaction[]>([]);
        this.accountingEntriesBSubject = new BehaviorSubject<AccountingEntry[]>([]);
        this.loanApplicationsBSubject = new BehaviorSubject<LoanApplication[]>([]);
        this.branchesBSubject = new BehaviorSubject<Branch[]>([]);
        this.servicesBSubject = new BehaviorSubject<Service[]>([]);
        this.globalServicesBSubject = new BehaviorSubject<GlobalService[]>([]);
        this.globalSettingsBSubject = new BehaviorSubject<GlobalSetting[]>([]);
        this.responseCodesBSubject = new BehaviorSubject<ResponseCode[]>([]);
        this.biReportsBSubject = new BehaviorSubject<BiReport[]>([]);
        this.messagesBSubject = new BehaviorSubject<Message[]>([]);
        this.channelLogsBSubject = new BehaviorSubject<ChannelLog[]>([]);
        this.ussdLogsBSubject = new BehaviorSubject<UssdLog[]>([]);
        this.b2cConfigsBehaviorSubject = new BehaviorSubject<B2cConfig[]>([]);
        this.coreSettingsBSubject = new BehaviorSubject<CoreSetting[]>([]);
        this.loanCyclesBSubject = new BehaviorSubject<LoanCycle[]>([]);
        this.pdAdjustmentsBSubject = new BehaviorSubject<PdAdjustment[]>([]);
        this.messageTemplatesBSubject = new BehaviorSubject<MessageTemplate[]>([]);
    }
}

