import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationService} from '../../services/notification.service';
import {AuthCredential} from '../../models/sharedInterfaces';
import {HttpErrorResponse} from '@angular/common/http';
import {ForgotPasswordComponent} from '../modals/forgot-password/forgot-password.component';
import {MatDialog} from '@angular/material/dialog';
import {Constants} from '../../helpers/constants';
import {LoadingService} from '../../services/loading.service';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    loadingBarColor = 'primary';
    registrationToggle: Boolean = false;
    isLoading: Boolean = false;
    authCredential: AuthCredential = {
        username: '',
        password: '',
    };
    passwordPattern = new RegExp(environment.regex__password);;
    returnUrl: string;
    modalReply: any;
    constants = new Constants();

    constructor(private authService: AuthService, private route: ActivatedRoute, private router: Router,
                private ns: NotificationService, private dialog: MatDialog, private loadingService: LoadingService) {
        this.redirectIfSessionIsPresent();

    }

    ngOnInit() {
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard';
        console.log('logged in')
        this.redirectIfSessionIsPresent();
    }

    onSubmitLogin() {
        this.isLoading = true;
        this.authService.login(this.authCredential).subscribe((res) => {
            this.isLoading = false;
            if (res && res.responseCode === '00') {
                this.ns.add('Login Successful! Redirecting...', 'success');
                this.invokeLoginProcess();
            } else {
                const responseDescription = res ? res.responseDescription : null
                this.ns.add('Could not log you in: ' + responseDescription, 'danger');
            }
        }, (error: HttpErrorResponse) => {
            this.isLoading = false;
            if (error.status === 401) {
                this.ns.add('Incorrect credentials!', 'danger');
            } else {
                this.ns.add('Error! Could not log in! Server error occured!', 'danger');
            }
        });
    }

    onForgotPassword() {
        this.openDialog();
    }

    openDialog() {
        const dialogRef = this.dialog.open(ForgotPasswordComponent, {
            width: '500px',
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
            this.modalReply = result;
        });
    }

    invokeLoginProcess() {
        console.log('Login Process invoked');
        this.loadingService.topLoadingBar.setIsLoading();
        this.authService.fetchPermissions()
            .then((res) => {
                console.log('Permissions fetched >> ', res);
                if (res.status === this.constants.FUNCTION_RESPONSE.STATUS.OK) {
                    // Ok... Proceed to login
                    console.log('permissions ok, redirecting', res);
                    this.router.navigate(['/dashboard'])
                        .then((data) => {
                            this.loadingService.topLoadingBar.setIsHidden();
                            console.log('Successful Login');
                        })
                        .catch(err => {
                            console.error('Error Logging in: Redirect >> ', err);
                            this.loadingService.topLoadingBar.setIsHidden();
                        });
                    return;
                } else {
                    console.error('Could not fetch permissions >> ', res.error ? res.error : 'Unknown Error');
                    return;
                }
            })
            .catch(err => {
                // Do nothing
                this.loadingService.topLoadingBar.setIsHidden();
                this.ns.add('Could not fetch permissions!', 'danger');
            })
    }

    redirectIfSessionIsPresent() {
        if (this.authService.sessionIsPresent()) {
            console.log('logged in');
            this.invokeLoginProcess();
            // this.router.navigate(['/dashboard'])
            //     .then(r => console.log('Navigated to dash'))
            //     .catch(err => console.error(err));
        }
    }
}
