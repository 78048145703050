import {Component, Input, OnInit} from '@angular/core';
import {FieldConfig} from '../../models/fields.interface';
import {FormGroup} from '@angular/forms';
import * as _moment from 'moment';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
// const moment = _moment;
// tslint:disable-next-line:no-duplicate-imports
import {default as _rollupMoment} from 'moment';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
    parse: {
        dateInput: 'YYYYMMDDHHmmss',
    },
    display: {
        dateInput: 'LL',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'YYYYMMDDHHmmss',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'app-date',
    templateUrl: './date.component.html',
    styleUrls: ['./date.component.scss'],
    providers: [
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]},
        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    ],
})

/**
 * Creates a Mat Date Component
 * @Params field : FieldConfig
 * @Params group  : FormGroup
 */
export class DateComponent implements OnInit {
    @Input() field: FieldConfig;
    @Input() group: FormGroup;
    defaultMinDate = moment().subtract(100, 'years');
    defaultMaxDate = moment().add(100, 'years');

    constructor() {
    }

    ngOnInit() {
    }

}
