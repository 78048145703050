import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {FieldConfig} from '../../models/fields.interface';

@Component({
    selector: 'app-check-box',
    templateUrl: './check-box.component.html',
    styleUrls: ['./check-box.component.scss']
})
export class CheckBoxComponent implements OnInit {
    @Input() field: FieldConfig;
    @Input() group: FormGroup;

    constructor() {
    }

    ngOnInit() {
    }

}
