import {Injectable} from '@angular/core';
import * as $ from 'jquery';
import 'bootstrap-notify';
import {BehaviorSubject} from 'rxjs';

// declare var showNotification: any;

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    notifications = [];
    bottomBarControls = {
        isShowing: false,
        timer: 10000
    }

    constructor() {
    }

    add(notification: String, type: String) {
        this.notifications.push(notification);
        this.showNotification('top', 'right', notification, type, 100);
    }

    addBottomBar(notification: String, type: String) {
        if (!this.bottomBarControls.isShowing) {
            this.bottomBarControls.isShowing = true
            setTimeout(() => {
                this.bottomBarControls.isShowing = false;
            }, this.bottomBarControls.timer)
            this.showNotification('bottom', 'left', notification, 'warning', this.bottomBarControls.timer);
        }
    }

    clear() {
        this.notifications = [];
    }

    getAll(): any[] {
        return this.notifications;
    }

    private showNotification(from, align, notification, type, timer) {
        // type can be 'info','success','warning','danger'
        var icon: string;
        // map Icon
        if (type) {
            switch (type) {
                case 'danger':
                    icon = 'error';
                    break;
                case 'warning':
                    icon = 'warning';
                    break;
                case 'success' :
                    icon = 'done';
                    break;
                case 'info':
                    icon = 'info';
                    break;
                default:
                    icon = 'info';
            }
        }
        $[`notify`](
            {
                icon: icon,
                message: notification

            }, {
                type: type,
                timer: timer,
                placement: {
                    from: from,
                    align: align
                },
                template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
                    '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
                    '<i class="material-icons" data-notify="icon">notification</i> ' +
                    '<span data-notify="title">{1}</span> ' +
                    '<span data-notify="message">{2}</span>' +
                    '<div class="progress" data-notify="progressbar">' +
                    '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
                    '</div>' +
                    '<a href="{3}" target="{4}" data-notify="url"></a>' +
                    '</div>'
            }
        );


        /*$.notify({
          icon: "notifications",
          message: "Welcome to <b>Material Dashboard</b> - a beautiful freebie for every web developer."

        },{
          type: type[color],
          timer: 4000,
          placement: {
            from: from,
            align: align
          },
          template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
              '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
              '<i class="material-icons" data-notify="icon">notifications</i> ' +
              '<span data-notify="title">{1}</span> ' +
              '<span data-notify="message">{2}</span>' +
              '<div class="progress" data-notify="progressbar">' +
              '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
              '</div>' +
              '<a href="{3}" target="{4}" data-notify="url"></a>' +
              '</div>'
        });*/
    }
}
