import {PageProfile} from '../models/modelBank';

export class Utils {
    makeQueryString(params): string {
        let queryString = '?';
        let counter = 0;
        for (const key of Object.keys(params)) {
            if (params[key] && counter < 1) {
                queryString += key + '=' + params[key];
                ++counter;
            } else if (params[key] && counter >= 1) {
                queryString += '&' + key + '=' + params[key];
                ++counter;
            } else if (params[key] || params[key] === null) {
                queryString += '';
            }
        }
        return queryString;
    }

    extractPaginationData(page: any, pageProfile: PageProfile) {
        pageProfile.totalPages = page.totalPages;
        pageProfile.last = page.last;
        pageProfile.first = page.first;
        pageProfile.totalElements = page.totalElements;
        return pageProfile;
    }
}
