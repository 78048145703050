import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Utils} from '../helpers/utils';
import {Constants} from '../helpers/constants';

@Injectable()
export class LoadingService {
    constants = new Constants();
    states = {
        tableState : this.constants.TABLE_STATE,
        buttonState : this.constants.BUTTON_STATE,
        pageState : this.constants.PAGE_STATE,
        topLoadingBarState: this.constants.TOP_BAR
    };
    csvDataIsLoading: BehaviorSubject<Boolean> = new BehaviorSubject<Boolean>(false);
    isLoadingBSubject: BehaviorSubject<Boolean> = new BehaviorSubject<Boolean>(false);
    primaryTableStateBSubject: BehaviorSubject<string> = new BehaviorSubject<string>(this.states.tableState.LOADING);
    primaryButtonStateBSubject: BehaviorSubject<string> = new BehaviorSubject<string>(this.states.buttonState.SHOW);
    primaryPageStateBSubject: BehaviorSubject<string> = new BehaviorSubject<string>(this.states.pageState.LOADING);
    topLoadingBarStateBSubject: BehaviorSubject<string> = new BehaviorSubject<string>(this.states.topLoadingBarState.HIDE);

    primaryTable = {
        setIsLoading: () => {
            this.primaryTableStateBSubject.next(this.states.tableState.LOADING);
        },
        setIsReady: () => {
            this.primaryTableStateBSubject.next(this.states.tableState.SHOW);
        },
        setNotFound: () => {
            this.primaryTableStateBSubject.next(this.states.tableState.NOT_FOUND);
        }
    };

    primaryButton = {
        setIsLoading: () => {
            this.primaryButtonStateBSubject.next(this.states.buttonState.LOADING);
        },
        setIsReady: () => {
            this.primaryButtonStateBSubject.next(this.states.buttonState.SHOW);
        }
    };

    primaryPage = {
        setIsLoading: () => {
            this.primaryPageStateBSubject.next(this.states.pageState.LOADING);
        },
        setIsReady: () => {
            this.primaryPageStateBSubject.next(this.states.pageState.SHOW);
        },
        setNotFound: () => {
            this.primaryPageStateBSubject.next(this.states.pageState.NOT_FOUND);
        },
        setIsError: () => {
            this.primaryPageStateBSubject.next(this.states.pageState.ERROR);
        }
    };

    topLoadingBar = {
        setIsLoading: () => {
            this.topLoadingBarStateBSubject.next(this.states.topLoadingBarState.LOADING);
        },
        setIsHidden: () => {
            this.topLoadingBarStateBSubject.next(this.states.topLoadingBarState.HIDE);
        },
    };


    constructor() {
    }

    setCsvDataIsLoading(value: Boolean) {
        this.csvDataIsLoading.next(value);
    }

    setIsLoading(value: Boolean) {
        this.isLoadingBSubject.next(value);
    }

}
