export class Constants {
    USSD_LOGS_TYPE = 'USSD_LOGS';
    AUDIT_LOGS_TYPE = 'AUDIT_LOGS';
    MESSAGES_TYPE = 'MESSAGES';
    LOANS_TYPE = 'LOANS';
    CUSTOMERS_TYPE = 'CUSTOMERS';
    USERS_TYPE = 'USERS';
    CUSTOMER_TRANSACTIONS = 'CUSTOMER_TRANSACTIONS';
    ACCOUNTING_ENTRIES_TYPE = 'ACCOUNTING_ENTRIES';

    REPORT_TYPES = {
        CSV: 'CSV',
        EXCEL: 'XLS'
    };
    TABLE_STATE = {
        LOADING: 'LOADING',
        NOT_FOUND: 'NOT_FOUND',
        SHOW: 'SHOW'
    };
    BUTTON_STATE = {
        LOADING: 'LOADING',
        SHOW: 'SHOW'
    };
    PAGE_STATE = {
        LOADING: 'LOADING',
        SHOW: 'SHOW',
        NOT_FOUND: 'NOT_FOUND',
        ERROR: 'ERROR'
    };
    TOP_BAR = {
        LOADING: 'LOADING',
        HIDE: 'HIDE'
    };
    FUNCTION_RESPONSE = {
        STATUS: {
            OK: 'OK',
            ERROR: 'ERROR'
        },
        ERRORS:
            {
                AUTHENTICATION: {
                    NO_USER_DETECTED: 'NO_USER_DETCTED'
                }
            }
    }
}

