import {UmRole} from './role';

export class User {
    No: number;
    id: string;
    first_name: string;
    middle_name: string;
    last_name: string;
    username?: string;
    password: string;
    role_id?: number;
    avatar?: string;
    bio?: string;
    address?: string;
    email: string;
    msisdn: string;
    email_verified_at?: string;
    date_created?: string;
    updated_at?: string;
    in_trash: boolean;
    created_by: string;
    last_password_change: string; 
}

export class UmUser {
    username: string;
    email: string;
    surname: string;
    firstName: string;
    msisdn: string;
    updatedBy: string; 
    roles: UmRole[];
    id: number;
    inTrash: boolean;
    organization: any;
    nationalId?: string;
    active?: Boolean;
    resetPassword?:Boolean;
}

export interface Role {
    id: string;
}
