import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, timeInterval, timeout } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';

@Injectable()
export class AuthErrorInterceptor implements HttpInterceptor {
    authSnackbarOff = true;
    constructor(private matSnackBar: MatSnackBar, private authService: AuthService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(error => {
            if (error.status === 401 && !request.url.includes('authenticate')) {
                // auto logout if 401 response returned from api
                // this.authenticationService.logout();
                if (this.authSnackbarOff) { // AuthSnackbar is already showing, dont show another
                    let message = error.error && error.error.message ? error.error.message + ' Consider logging in again' : 'Your session seems to have ended. Consider logging in again';
                    this.authSnackbarOff = false;
                    this.openSnackBar(message, 'Log In again');
                    setTimeout(() => {
                        this.authService.logout()
                    }, 7500)
                }
                // For authentication errors, dont forward to components - its unecessary.
                return;
            } else {
                return throwError(error);
            }
        }))
    }

    async openSnackBar(message: string, action: string) {
        const loginPromptSnackbar = await this.matSnackBar.open(
            message,
            action, {
            duration: 3000,
            announcementMessage: 'announcement'
        });
        loginPromptSnackbar.onAction().subscribe(() => {
            loginPromptSnackbar.dismiss();
            this.authService.logout();
        })
        loginPromptSnackbar.afterDismissed().subscribe(() => {
            this.authSnackbarOff = true;
        })
    }
}
