import {Component, Inject, OnInit} from '@angular/core';
import {ModalService} from '../../../services/modal.service';
import {LoadingService} from '../../../services/loading.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {NotificationService} from '../../../services/notification.service';
import {AuthService} from '../../../services/auth.service';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

    isLoading: Boolean = false;
    forgotPasswordEmail = '';

    constructor(private modalService: ModalService,
                private loadingService: LoadingService,
                private dialog: MatDialog,
                private ns: NotificationService,
                private authService: AuthService,
                public dialogRef: MatDialogRef<ForgotPasswordComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit() {
        this.loadingService.isLoadingBSubject.asObservable().subscribe((res: Boolean) => {
            this.isLoading = res;
        });
    }

    setLoading(bool: Boolean) {
        this.loadingService.setIsLoading(bool);
    }

    onSubmit() {
        this.setLoading(true);
        this.authService.initiateResetPassword(this.forgotPasswordEmail).subscribe(res => {
            this.setLoading(false);
            if (res.responseCode === '00') {
                // Success response
                this.ns.add('A link has been sent to your email', 'success');
                this.dialogRef.close(res => {console.log('Dialog left with >> ', res);
                });
            } else {
                // Error
                const errMessage: string = res.responseDescription ? res.responseDescription : 'Could not process your request.';
                this.ns.add('Error.' + errMessage, 'danger');
            }
        }, error => {
            this.setLoading(false);
            // Http error
            const errMessage: string =
                error.error && error.error.responseDescription ? error.error.responseDescription : 'Could not process your request.';
            this.ns.add('Error.' + errMessage, 'danger');
        });
    }

}
