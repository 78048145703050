import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {NotificationService} from '../services/notification.service';
import {Injectable} from '@angular/core';

@Injectable()
export class NetworkStatusInterceptor implements HttpInterceptor {
    constructor(private ns: NotificationService) {
    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        return next.handle(request).pipe(catchError((err: HttpErrorResponse) => {
            if (err.status === 0) {
                this.ns.addBottomBar('Could not connect to server. KIndly check your connection.', 'warning');
            }
            return throwError(err);
        }))
    }
}
